<template>
  <div class="bg cl">
    <van-tabs v-model="active" sticky animated class="mytab cl">
      <van-tab title="全部">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cl cardbox" v-for="item in list" :key="item" @click="goinfo">
              <div class="card">
                <div class="top">
                  <img src="../../assets/img/help_0.png" alt="" />
                  <span>同仁堂大药房(光谷大道店)</span>
                </div>
                <div class="tmail">
                  <img src="../../assets/img/help_0.png" alt="" class="" />
                  <div class="">
                    <h4>硝苯地平控释片</h4>
                    <p class="size">500g 直径10mm（药品规格、剂量）</p>
                    <p>不支持7天无理由退换</p>
                    <p><span>¥ 36.00</span> <em>x1</em></p>
                  </div>
                </div>
                <div class="btnbox">
                  <p>应付金额:258.00</p>
                  <div>
                    <span>取消订单</span>
                    <span class="on">立即付款</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="待付款">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cl cardbox" v-for="item in list" :key="item">
              <div class="card">
                <div class="top">
                  <img src="../../assets/img/help_0.png" alt="" />
                  <span>同仁堂大药房(光谷大道店)</span>
                </div>
                <div class="tmail">
                  <img src="../../assets/img/help_0.png" alt="" class="" />
                  <div class="">
                    <h4>硝苯地平控释片</h4>
                    <p class="size">500g 直径10mm（药品规格、剂量）</p>
                    <p>不支持7天无理由退换</p>
                    <p><span>¥ 36.00</span> <em>x1</em></p>
                  </div>
                </div>
                <div class="btnbox">
                  <p>应付金额:258.00</p>
                  <div>
                    <span>取消订单</span>
                    <span class="on">立即付款</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="待发货">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cl cardbox" v-for="item in list" :key="item">
              <div class="card">
                <div class="top">
                  <img src="../../assets/img/help_0.png" alt="" />
                  <span>同仁堂大药房(光谷大道店)</span>
                </div>
                <div class="tmail">
                  <img src="../../assets/img/help_0.png" alt="" class="" />
                  <div class="">
                    <h4>硝苯地平控释片</h4>
                    <p class="size">500g 直径10mm（药品规格、剂量）</p>
                    <p>不支持7天无理由退换</p>
                    <p><span>¥ 36.00</span> <em>x1</em></p>
                  </div>
                </div>
                <div class="btnbox">
                  <p>应付金额:258.00</p>
                  <div class="daifahuo">
                      待发货
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已发货">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cl cardbox" v-for="item in list" :key="item">
              <div class="card">
                <div class="top">
                  <img src="../../assets/img/help_0.png" alt="" />
                  <span>同仁堂大药房(光谷大道店)</span>
                </div>
                <div class="tmail">
                  <img src="../../assets/img/help_0.png" alt="" class="" />
                  <div class="">
                    <h4>硝苯地平控释片</h4>
                    <p class="size">500g 直径10mm（药品规格、剂量）</p>
                    <p>不支持7天无理由退换</p>
                    <p><span>¥ 36.00</span> <em>x1</em></p>
                  </div>
                </div>
                <div class="btnbox">
                  <p>应付金额:258.00</p>
                  <div>
                    <span>查看物理</span>
                    <span class="on">确认收货</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已完成">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cl cardbox" v-for="item in list" :key="item">
              <div class="card">
                <div class="top">
                  <img src="../../assets/img/help_0.png" alt="" />
                  <span>同仁堂大药房(光谷大道店)</span>
                </div>
                <div class="tmail">
                  <img src="../../assets/img/help_0.png" alt="" class="" />
                  <div class="">
                    <h4>硝苯地平控释片</h4>
                    <p class="size">500g 直径10mm（药品规格、剂量）</p>
                    <p>不支持7天无理由退换</p>
                    <p><span>¥ 36.00</span> <em>x1</em></p>
                  </div>
                </div>
                <div class="btnbox">
                  <p>应付金额:258.00</p>
                 <div class="daifahuo">
                      交易已完成
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: this.$route.params.active,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  mounted() {},
  methods: {
    // 详情
    goinfo() {
      this.$router.push({ name: "orderinfo" });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style scoped>
/deep/.mytab .van-tab--active .van-tab__text {
  /* color: rgba(255, 255, 255, 1); */
}
/deep/.mytab .van-tab--active .van-tab {
  border: none;
  box-sizing: border-box;
}

/deep/.mytab .van-tab__text {
  z-index: 10;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 80px;
  height: 6px;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.cardbox {
  padding: 16px;
  padding-bottom: 0;
  background: #f5f5f5;
  .card {
    padding: 16px;
    background: #fff;
    text-align: left;
    .top {
      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        margin-top: -4px;
      }
      span {
        font-size: 16px;
        color: #2d2d2d;
        vertical-align: top;
      }
    }
    .tmail {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #efefef;
      img {
        width: 80px;
        height: 80px;
        margin-right: 13px;
      }
      h4 {
        font-size: 16px;
        color: rgba(45, 45, 45, 1);
        font-weight: 700;
        margin-bottom: 6px;
      }
      div {
        width: 230px;
      }
      p {
        font-size: 12px;
        margin-bottom: 8px;
        span {
          color: rgba(251, 81, 81, 1);
          font-size: 14px;
        }
        em {
          float: right;
          font-style: normal;
        }
      }
      .size {
        font-size: 12px;
        color: rgba(29, 174, 252, 1);
        margin-bottom: 8px;
      }
    }
    .btnbox {
      display: flex;
      padding-top: 11px;
      justify-content: space-between;
      .daifahuo{
            color: rgba(29,174,252,1);
        
      }
      div {
        span {
          display: inline-block;
          width: 68px;
          height: 32px;
          border: 1px solid #c2c2c2;
          border-radius: 8px;
          line-height: 32px;
          text-align: center;
          font-size: 12px;
          margin-left: 16px;
          &.on{
            color: rgba(29,174,252,1);
          border: 1px solid rgba(29,174,252,1);

          }
        }
      }
    }
  }
}

.mylist {
  text-align: left;
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    // border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>
